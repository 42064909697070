<template>
  <div class="d-flex justify-content-center mt-10">
    <div class="container">
      <div class="card card-custom card-transparent">
        <div class="card card-custom card-shadowless rounded-top-0">
          <v-card>
            <v-data-table
                fixed-header
                class="elevation-1"
                :headers="tableData.headers"
                :pageCount="totalPages"
                :page="page"
                :server-items-length="totalResults"
                :items="dataTableList"
                :loading="reservationLoading"
                :options.sync="options"
                item-key="ID"
                :items-per-page="itemsPerPage"
                :footer-props="footerProps"
            >
              <template v-slot:item.code="{ item }">
                {{ item.code }}
              </template>
              <template v-slot:item.date_of_birth="{ item }">
              <span v-if="item.date_of_birth != null">{{
                  new Date(item.date_of_birth).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  })
                }}</span>
              </template>
              <template v-slot:item.status="{ item }">
              <span>
                <v-icon color="green" v-if="item.status == true"
                >mdi-check-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-minus-circle</v-icon>
              </span>
              </template>
              <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt != null"
              >{{
                  new Date(item.createdAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
                <span v-else>---</span>
              </template>
              <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt != null"
              >{{
                  new Date(item.createdAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
                <span v-else>---</span>
              </template>
              <template v-slot:item.reservation_date_time="{ item }">
              <span v-if="item.reservation_date_time != null"
              >{{
                  new Date(item.reservation_date_time).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
                <span v-else>---</span>
              </template>
              <template v-slot:item.subscription_date="{ item }">
              <span v-if="item.subscription_date != null">{{
                  new Date(item.subscription_date).toLocaleDateString()
                }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn text icon color="green">
                  <v-icon
                      v-if="item.status == 1"
                      @click="$router.push('/resume/' + item.code)"
                  >mdi-folder-account
                  </v-icon
                  >
                </v-btn>
                <v-btn text icon color="red" v-if="isDeletable(item)">
                  <v-icon @click="deleteItem(item.code)">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline"
                      >{{ $t("COMMON.DIALOG.DELETE_CONFIRM") }}
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                        >{{ $t("COMMON.DIALOG.ABORT") }}
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm(codeToDelete)"
                        >{{ $t("COMMON.DIALOG.OK") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog" max-width="500px" :persistent="true">
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  FETCH_RESERVATIONS_LIST,
  UPDATE_RESERVATIONS_DATA
} from "@/core/services/store/reservations.module";
import moment from "moment";

export default {
  data() {
    return {
      codeToDelete: String,
      dialogDelete: false,
      filters: {
        query: [],
        filters: {
          'citizen.code': {
            _eq: ''
          }
        },
        sort: {},
        limit: 100,
        page: 1
      },
      tableData: {
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.CODE"),
            align: "left",
            sortable: true,
            value: "code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.EVENT_TITLE"),
            value: "event.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.DOSE"),
            value: "dose.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.RESERVATION_DATE"),
            value: "reservation_date_time"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.REGISTRATION_DATE"),
            value: "createdAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.UPDATED_AT"),
            value: "updatedAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.STATUS"),
            value: "status"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.OPERATION"),
            value: "action",
            sortable: false
          }
        ]
      },
      page: 0,
      totalPage: Number,
      totalResults: Number,
      numberOfPages: 1,
      options: {},
      itemsPerPage: 10,
      optionsLength: 6,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        itemsPerPage: 20
      },
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [10, 20, 50, 100],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION")
      },
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isLoggingLoading", "reservationsList", "reservationLoading", "currentUserBaseInfo"]),
    dataTableList() {
      return this.reservationsList.data;
    }
  },
  methods: {
    isDeletable(item) {
      let res = moment(new Date(item.reservation_date_time));
      let start = moment(new Date(item.event.start_date));
      let today =  moment(new Date());
      return (
          item.status == 1 &&
          res.isAfter(today) &&
          start.isAfter(today)
      ) ? true : false;
    },
    readDataFromAPI(reset) {
      const {page, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber;
      if (reset == true) {
        pageNumber = 1;
        this.filters.limit = itemsPerPage;
      } else {
        pageNumber = page == 0 ? 1 : page;
        this.filters.limit = itemsPerPage;
      }
      this.filters.page = pageNumber;
      if (sortBy != undefined && sortBy.length > 0) {
        this.filters.sort = [
          {
            direction: sortDesc != undefined && sortDesc[0] ? "DESC" : "ASC",
            field: sortBy[0]
          }
        ];
      }
      if (this.currentUserBaseInfo.code) {
        this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
      }
    },
    deleteItem(item) {
      this.codeToDelete = item;
      this.editedIndex = this.dataTableList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var item = {};
      item.code = this.codeToDelete;
      item.status = false;
      this.$store.dispatch(UPDATE_RESERVATIONS_DATA, item);
      this.dataTableList.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({name: 'login_page'});
    }
  },
  components: {},
  watch: {
    currentUserBaseInfo() {
      if (this.currentUserBaseInfo.code !== undefined && this.currentUserBaseInfo.code !== '') {
        this.filters.filters['citizen.code']._eq = this.currentUserBaseInfo.code
        this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
      }
    },
    isAuthenticated() {

    },
    reservationsList() {
      this.totalPage = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
      this.totalResults = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total
          : 0;
      this.page = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
    },
    options: {
      handler() {
        // this.readDataFromAPI();
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  }
}
</script>
